// src/components/CartMenu.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades } from "../../theme";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  setCount,
  setStockAlerts, // Nieuwe action
  clearStockAlert, // Nieuwe action
} from "../../state";
import { BASE_URL } from "../../api";
import { useTheme } from "@mui/material/styles";

const FREE_ITEMS_START_DATE = new Date(
  process.env.REACT_APP_FREE_ITEMS_START_DATE
);
const API_BASE_URL = BASE_URL;

const useFreeItem = (totalPrice) => {
  const [freeItem, setFreeItem] = React.useState(null);
  const [alwaysFreeItems, setAlwaysFreeItems] = React.useState([]);

  React.useEffect(() => {
    const fetchAlwaysFreeItems = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$eq]=0`
        );
        const data = await response.json();
        if (data && data.data && Array.isArray(data.data)) {
          setAlwaysFreeItems(data.data);
        }
      } catch (error) {
        console.error("Error fetching always free items:", error);
      }
    };
    fetchAlwaysFreeItems();
  }, []);

  React.useEffect(() => {
    const fetchEligibleFreeItems = async () => {
      try {
        if (new Date() >= FREE_ITEMS_START_DATE) {
          const response = await fetch(
            `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$lte]=${totalPrice}`
          );
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            const eligibleItems = data.data.filter(
              (item) =>
                item.attributes &&
                item.attributes.freeFromPrice <= totalPrice &&
                item.attributes.freeFromPrice !== 0
            );
            if (eligibleItems.length) {
              eligibleItems.sort(
                (a, b) =>
                  b.attributes.freeFromPrice - a.attributes.freeFromPrice
              );
              setFreeItem(eligibleItems[0]);
            } else {
              setFreeItem(null);
            }
          }
        } else {
          setFreeItem(null);
        }
      } catch (error) {
        console.error("Error fetching eligible free items:", error);
      }
    };
    fetchEligibleFreeItems();
  }, [totalPrice]);

  return [freeItem, alwaysFreeItems];
};

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GrayedIconButton = styled(IconButton)`
  color: #aaa !important;
  cursor: not-allowed !important;
`;

const StrikethroughPrice = styled(Typography)`
  text-decoration: line-through;
  color: ${shades.neutral[500]};
  margin-right: 10px;
`;

const AlertMessage = styled(Alert)`
  margin-top: 10px;
  border-radius: 8px;
  background-color: ${shades.secondary[100]};
  color: ${shades.secondary[900]};
`;

const CartMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const stockAlerts = useSelector((state) => state.cart.stockAlerts); // Haal stockAlerts uit Redux store

  // Verwijdert alerts van localStorage bij wijziging
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const fetchStockQuantity = async (itemId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/items/${itemId}`);
      const data = await response.json();
      return data.data.attributes.stockQuantity;
    } catch (error) {
      console.error("Error fetching stock quantity:", error);
      return -1;
    }
  };

  const handleCountChange = async (item, increment = true) => {
    const currentStock = await fetchStockQuantity(item.id);
    if (currentStock < 0) {
      // Handle stock fetch error if needed
      return;
    }

    let newCount = increment ? item.count + 1 : item.count - 1;

    // Controleer maximale hoeveelheid per bestelling
    if (item.attributes.maxPerOrder !== null) {
      if (newCount > item.attributes.maxPerOrder) {
        newCount = item.attributes.maxPerOrder;
        dispatch(
          setStockAlerts({
            [item.id]: `Maximale hoeveelheid ${item.attributes.name} per bestelling: ${item.attributes.maxPerOrder}. We hebben het aangepast naar ${item.attributes.maxPerOrder}.`,
          })
        );
      }
    }

    // Controleer of het gewenste aantal niet de voorraad overschrijdt
    if (newCount > currentStock) {
      newCount = currentStock;
      dispatch(
        setStockAlerts({
          [item.id]: `Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`,
        })
      );
    } else {
      // Als het nieuwe aantal binnen de voorraad past, verwijder de alert
      dispatch(clearStockAlert({ id: item.id }));
    }

    dispatch(setCount({ id: item.id, count: newCount }));
  };

  const totalPrice = parseFloat(
    cart
      .reduce((total, item) => {
        const productPrice = item.attributes.priceDiscount
          ? item.attributes.priceDiscount
          : item.attributes.price;
        return total + item.count * productPrice;
      }, 0)
      .toFixed(2)
  );

  const totalPriceForFreeItems = parseFloat(
    cart
      .filter((item) => item.attributes.countFreeFireworks === true)
      .reduce((total, item) => {
        const productPrice = item.attributes.priceDiscount
          ? item.attributes.priceDiscount
          : item.attributes.price;
        return total + item.count * productPrice;
      }, 0)
      .toFixed(2)
  );

  const [freeItem, alwaysFreeItems] = useFreeItem(totalPriceForFreeItems);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);

  // ====== Toegevoegd: useEffect voor voorraadcontrole ======
  useEffect(() => {
    const checkStock = async () => {
      const alerts = {};
      for (const item of cart) {
        const currentStock = await fetchStockQuantity(item.id);
        if (currentStock < 0) {
          alerts[
            item.id
          ] = `Kon voorraad niet controleren voor ${item.attributes.name}.`;
          continue;
        }
        if (item.count > currentStock) {
          dispatch(setCount({ id: item.id, count: currentStock }));
          alerts[
            item.id
          ] = `Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`;
        }
      }
      if (Object.keys(alerts).length > 0) {
        dispatch(setStockAlerts(alerts));
      }
    };

    if (cart.length > 0) {
      checkStock();
    }
  }, [cart, dispatch]);
  // ===========================================================

  return (
    <Box
      display={isCartOpen ? "block" : "none"}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="0"
      overflow="auto"
    >
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px, 30%)"
        height="100%"
        backgroundColor={theme.palette.background.paper}
        color={theme.palette.text.primary}
      >
        <Box padding="30px" overflow="auto" height="100%">
          <FlexBox mb="15px">
            <Typography variant="h3">WINKELWAGEN ({cart.length})</Typography>
            <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
              <CloseIcon />
            </IconButton>
          </FlexBox>

          <Divider />

          <Box>
            {cart.map((item) => {
              const productPrice = parseFloat(
                (
                  item.count *
                  (item.attributes.priceDiscount
                    ? item.attributes.priceDiscount
                    : item.attributes.price)
                ).toFixed(2)
              );
              return (
                <Box key={`${item.attributes.name}-${item.id}`}>
                  <FlexBox p="15px 0">
                    <Box flex="1 1 40%">
                      <img
                        onClick={() => {
                          navigate(`/item/${item.id}`);
                          dispatch(setIsCartOpen({}));
                        }}
                        alt={item?.attributes?.name}
                        width="50%"
                        height="auto"
                        src={`${API_BASE_URL}${
                          item?.attributes?.image?.data?.attributes?.formats
                            ?.medium?.url ||
                          item?.attributes?.image?.data?.attributes?.url
                        }`}
                        style={{
                          objectFit: "contain",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          cursor: "pointer",
                        }}
                        loading="lazy"
                      />
                    </Box>
                    <Box flex="1 1 60%">
                      <FlexBox mb="5px">
                        <Typography fontWeight="bold">
                          {item.attributes.name}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            dispatch(removeFromCart({ id: item.id }));
                            dispatch(clearStockAlert({ id: item.id })); // Verwijder stock alert
                            localStorage.setItem("cart", JSON.stringify(cart));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </FlexBox>
                      <Typography>
                        {item.attributes.shortDescription}
                      </Typography>
                      <FlexBox m="15px 0">
                        <Box
                          display="flex"
                          alignItems="center"
                          border={`1.5px solid ${shades.neutral[500]}`}
                        >
                          <IconButton
                            onClick={() => handleCountChange(item, false)}
                            disabled={item.count <= 1}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <Typography>{item.count}</Typography>
                          <IconButton
                            onClick={() => handleCountChange(item, true)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        <Typography fontWeight="bold">
                          €{productPrice.toFixed(2)}
                        </Typography>
                      </FlexBox>
                    </Box>
                  </FlexBox>

                  {/* ====== Toegevoegd: Voorraadalert per item ====== */}
                  {stockAlerts[item.id] && (
                    <AlertMessage
                      severity="warning"
                      onClose={() => {
                        dispatch(clearStockAlert({ id: item.id }));
                      }}
                    >
                      {stockAlerts[item.id]}
                    </AlertMessage>
                  )}
                  {/* ================================================ */}

                  <Divider />
                </Box>
              );
            })}

            {alwaysFreeItems.length > 0 && (
              <Box mt={3}>
                <Typography variant="h6">Gratis Producten</Typography>
                {alwaysFreeItems.map((item) => (
                  <Box key={`always-free-${item.id}`}>
                    <FlexBox p="15px 0">
                      <Box flex="1 1 40%">
                        <img
                          alt={item?.attributes?.name}
                          width="50%"
                          height="auto"
                          src={`${API_BASE_URL}${
                            item?.attributes?.image?.data?.attributes?.formats
                              ?.medium?.url ||
                            item?.attributes?.image?.data?.attributes?.url
                          }`}
                          style={{
                            objectFit: "contain",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          loading="lazy"
                        />
                      </Box>
                      <Box flex="1 1 60%">
                        <FlexBox mb="5px">
                          <Typography fontWeight="bold">
                            {item.attributes.name} (Gratis)
                          </Typography>
                          <Tooltip title="Kan niet verwijderd worden">
                            <GrayedIconButton disabled>
                              <CloseIcon />
                            </GrayedIconButton>
                          </Tooltip>
                        </FlexBox>
                        <Typography>
                          {item.attributes.shortDescription}
                        </Typography>
                        <FlexBox m="15px 0">
                          <Box
                            display="flex"
                            alignItems="center"
                            border={`1.5px solid ${shades.neutral[500]}`}
                          >
                            <GrayedIconButton disabled>
                              <RemoveIcon />
                            </GrayedIconButton>
                            <Typography>{item.count || 1}</Typography>
                            <GrayedIconButton disabled>
                              <AddIcon />
                            </GrayedIconButton>
                          </Box>
                          <StrikethroughPrice>
                            €{parseFloat(item.attributes.price).toFixed(2)}
                          </StrikethroughPrice>
                        </FlexBox>
                      </Box>
                    </FlexBox>
                    <Divider />
                  </Box>
                ))}
              </Box>
            )}

            {freeItem && (
              <Box mt={3}>
                <FlexBox p="15px 0">
                  <Box flex="1 1 40%">
                    <img
                      alt={freeItem.attributes.name}
                      width="50%"
                      height="auto"
                      src={`${API_BASE_URL}${
                        freeItem?.attributes?.image?.data?.attributes?.formats
                          ?.medium?.url ||
                        freeItem?.attributes?.image?.data?.attributes?.url
                      }`}
                      style={{
                        objectFit: "contain",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      loading="lazy"
                    />
                  </Box>
                  <Box flex="1 1 60%">
                    <FlexBox mb="5px">
                      <Typography fontWeight="bold">
                        {freeItem.attributes.name} (Gratis)
                      </Typography>
                      <Tooltip title="Kan niet verwijderd worden">
                        <GrayedIconButton disabled>
                          <CloseIcon />
                        </GrayedIconButton>
                      </Tooltip>
                    </FlexBox>
                    <Typography>
                      {freeItem.attributes.shortDescription}
                    </Typography>
                    <FlexBox m="15px 0">
                      <Box
                        display="flex"
                        alignItems="center"
                        border={`1.5px solid ${shades.neutral[500]}`}
                      >
                        <GrayedIconButton disabled>
                          <RemoveIcon />
                        </GrayedIconButton>
                        <Typography>{freeItem.count || 1}</Typography>
                        <GrayedIconButton disabled>
                          <AddIcon />
                        </GrayedIconButton>
                      </Box>
                      <StrikethroughPrice>
                        €{parseFloat(freeItem.attributes.price).toFixed(2)}
                      </StrikethroughPrice>
                    </FlexBox>
                  </Box>
                </FlexBox>
                <Divider />
              </Box>
            )}
          </Box>

          {/* ACTIONS */}
          <Box m="20px 0">
            <FlexBox m="20px 0">
              <Typography fontWeight="bold">SUBTOTAL</Typography>
              <Typography fontWeight="bold">
                €{totalPrice.toFixed(2)}
              </Typography>
            </FlexBox>
            {totalPrice < 25 && (
              <Typography color="error" textAlign="center" mb={2}>
                Online bestellen kan vanaf €25.
              </Typography>
            )}
            <Button
              sx={{
                backgroundColor:
                  totalPrice < 25
                    ? theme.palette.text.secondary
                    : theme.palette.primary.main,
                color: "white",
                borderRadius: 0,
                minWidth: "100%",
                padding: "20px 40px",
                m: "20px 0",
              }}
              disabled={totalPrice < 25}
              onClick={() => {
                navigate("/checkout");
                dispatch(setIsCartOpen({}));
              }}
            >
              AFREKENEN
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartMenu;
