import React from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const freeProducts = [
  {
    name: "GRATIS CROSSING STARS 14 SHOTS",
    value: "T.W.V. € 12,99",
    minOrder: 40,
    img: "https://api.vuurwerkbruchem.com/uploads/7200_CLASSICK_CROSSING_STARS_db713dba63.png",
    query: "crossing stars",
  },
  {
    name: "GRATIS ELECTRICITY 25 SHOTS",
    value: "T.W.V. € 19,99",
    minOrder: 80,
    img: "https://api.vuurwerkbruchem.com/uploads/7202_CLASSICK_ELECTRICK_77f1bee791.png",
    query: "electricity",
  },
  {
    name: "GRATIS DARK EVIL 20 SHOTS",
    value: "T.W.V. € 37,50",
    minOrder: 150,
    img: "https://api.vuurwerkbruchem.com/uploads/7204_CLASSICK_DARK_EVIL_bf5d07ac59.png",
    query: "dark evil",
  },
  {
    name: "GRATIS PANTER BOX XL 36 SHOTS",
    value: "T.W.V. € 59,99",
    minOrder: 250,
    img: "https://api.vuurwerkbruchem.com/uploads/7206_CLASSICK_PANTHER_BOX_f96d871875.png",
    query: "panter",
  },
  {
    name: "GRATIS NORDIC FIRE COMPOUND 170 SHOTS",
    value: "T.W.V. € 99,00",
    minOrder: 450,
    img: "https://api.vuurwerkbruchem.com/uploads/7208_CLASSICK_NORDIC_FIRE_ee4cd2a0f3.png",
    query: "nordic fire",
  },
];

const GratisVuurwerk = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      width="80%"
      margin="60px auto"
      padding="20px"
      borderRadius="10px"
      className="shift-on-search"
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        textAlign="center"
        fontWeight="bold"
        color="primary"
        marginBottom="20px"
      >
        GRATIS VUURWERK BIJ JE BESTELLING!
      </Typography>

      {freeProducts.map((product, index) => (
        <Paper
          key={index}
          elevation={3}
          sx={{
            padding: "20px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#ffffff",
            transition: "transform 0.3s ease-in-out",
            maxWidth: isMobile ? "none" : "50%",
            marginLeft: "auto",
            marginRight: "auto",
            "&:hover": {
              transform: "scale(1.05)",
            },
            "&:focus-within, &:focus-visible": isMobile
              ? {
                  transform: "scale(1.1)",
                  boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
                }
              : {},
          }}
          tabIndex={0} // Make the Paper component focusable on mobile
          onClick={() =>
            (window.location.href = `/search?query=${product.query}`)
          }
        >
          <img
            src={product.img}
            alt={product.name}
            style={{ marginBottom: "15px", width: "120px" }}
          />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
            gutterBottom
          >
            {product.name}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {product.value}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Bij een minimale bestelwaarde van €{product.minOrder}
          </Typography>
        </Paper>
      ))}

      <Box marginTop="60px" textAlign="center">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: "30px",
            padding: "15px 30px",
            fontSize: "1.2rem",
            fontWeight: "bold",
            backgroundColor: "#e3197e",
            "&:hover": {
              backgroundColor: "#c71585",
            },
          }}
          onClick={() => (window.location.href = "/products")}
        >
          Bekijk ons volledige assortiment
        </Button>
      </Box>

      <Typography
        variant="body1"
        textAlign="center"
        color="textSecondary"
        marginTop="40px"
      >
        Let op: Sommige producten zijn uitgesloten van deze actie. Het staat
        altijd bij het product vermeld of het meetelt voor gratis vuurwerk.
        Mocht het gratis vuurwerk op zijn, dan kan er een vergelijkbaar product
        van dezelfde waarde worden geleverd. OP=OP, dus het kan helaas ook
        voorkomen dat er geen gratis artikel meer beschikbaar is.
      </Typography>
    </Box>
  );
};

export default GratisVuurwerk;
