import {
  Box,
  Button,
  IconButton,
  Typography,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState, memo, useCallback } from "react";
import Item from "../../components/Item";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades } from "../../theme";
import { addToCart } from "../../state";
import { useDispatch } from "react-redux";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../api";

const API_BASE_URL = BASE_URL;

const ItemDetails = () => {
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [value, setValue] = useState("description");
  const [count, setCount] = useState(1);
  const [item, setItem] = useState(null);
  const [relatedItems, setRelatedItems] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const fetchRelatedItems = useCallback(async () => {
    if (item?.attributes?.supplier) {
      const response = await fetch(
        `${API_BASE_URL}/api/items?populate=image&filters[supplier][$eq]=${item.attributes.supplier}`
      );
      const data = await response.json();
      setRelatedItems(data.data);
    }
  }, [item?.attributes?.supplier]);

  const getItem = useCallback(async () => {
    const response = await fetch(
      `${BASE_URL}/api/items/${itemId}?populate=image`
    );
    const itemJson = await response.json();
    setItem(itemJson.data);
    setLoading(false);
  }, [itemId]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  useEffect(() => {
    if (item?.attributes?.supplier) {
      fetchRelatedItems();
    }
  }, [item, fetchRelatedItems]);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
    setIsFavorite(favorites.includes(item?.id));
  }, [item]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleFavorite = () => {
    let favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
    if (isFavorite) {
      favorites = favorites.filter((id) => id !== item.id);
    } else {
      favorites.push(item.id);
    }
    localStorage.setItem("favorites", JSON.stringify(favorites));
    setIsFavorite(!isFavorite);
  };

  const handleAddToCart = () => {
    dispatch(addToCart({ item: { ...item, count } }));
  };

  if (loading) {
    return (
      <Box width="80%" m="60px auto" className="shift-on-search">
        <Typography variant="h3" align="center">
          Laden...
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="80%" m="60px auto" className="shift-on-search">
      <Helmet>
        <title>
          {item?.attributes?.name
            ? `${item.attributes.name} - Vuurwerk Bruchem`
            : "Vuurwerk Bruchem"}
        </title>
        <meta
          name="description"
          content={`Koop ${item?.attributes?.name} bij Vuurwerk Bruchem. ${
            item?.attributes?.shortDescription || ""
          }`}
        />
      </Helmet>
      <Box display="flex" flexWrap="wrap" columnGap="40px">
        {/* IMAGES */}
        <Box flex="1 1 40%" mb="0px">
          <img
            alt={item?.name}
            width="70%"
            height="70%"
            src={`${API_BASE_URL}${
              item?.attributes?.image?.data?.attributes?.formats?.medium?.url ||
              item?.attributes?.image?.data?.attributes?.url
            }`}
            style={{ objectFit: "contain" }}
          />
        </Box>

        {/* ACTIONS */}
        <Box flex="1 1 50%" mb="0px">
          <Box m="65px 0 25px 0">
            <Typography variant="h3">{item?.attributes?.name}</Typography>

            {item?.attributes?.priceDiscount &&
            item?.attributes?.priceDiscount < item?.attributes?.price ? (
              <>
                <Typography sx={{ textDecoration: "line-through" }}>
                  €{parseFloat(item?.attributes?.price).toFixed(2)}
                </Typography>
                <Typography>
                  €{parseFloat(item?.attributes?.priceDiscount).toFixed(2)}
                </Typography>
              </>
            ) : (
              <Typography>
                €{parseFloat(item?.attributes?.price).toFixed(2)}
              </Typography>
            )}

            <Typography sx={{ mt: "20px" }}>
              {item?.attributes?.longDescription}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" minHeight="50px">
            <Box
              display="flex"
              alignItems="center"
              border={`1.5px solid ${shades.neutral[300]}`}
              mr="20px"
              p="2px 5px"
            >
              <IconButton
                onClick={() => setCount(Math.max(count - 1, 0))}
                disabled={item?.attributes?.outOfStock}
                sx={{
                  color: theme.palette.text.primary, // Gebruik dezelfde kleur als de tekst en border
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover, // Consistente hover-kleur
                  },
                }}
              >
                <RemoveIcon />
              </IconButton>

              <Typography sx={{ p: "0 5px" }}>{count}</Typography>
              <IconButton
                onClick={() => setCount(count + 1)}
                disabled={item?.attributes?.outOfStock}
                sx={{
                  color: theme.palette.text.primary, // Gebruik dezelfde kleur als de tekst en border
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover, // Consistente hover-kleur
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              onClick={handleAddToCart}
              disabled={item?.attributes?.outOfStock}
              sx={{
                backgroundColor: item?.attributes?.outOfStock
                  ? theme.palette.neutral.dark // Gebruik de neutrale donkere kleur voor uitverkochte items
                  : theme.palette.secondary.main, // Gebruik de secundaire kleur voor beschikbare items
                color: "white",
                borderRadius: 0,
                minWidth: "150px",
                padding: "10px 40px",
                "&:hover": {
                  backgroundColor: item?.attributes?.outOfStock
                    ? theme.palette.neutral.dark // Geen hover effect voor uitverkochte items
                    : theme.palette.secondary.dark, // Donkerdere variant bij hover voor beschikbare items
                },
              }}
            >
              {item?.attributes?.outOfStock ? "Uitverkocht" : "In winkelwagen"}
            </Button>
          </Box>

          <Box>
            <Box m="20px 0 5px 0" display="flex">
              <IconButton onClick={toggleFavorite}>
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
              </IconButton>
              <Typography sx={{ ml: "5px" }}>
                {isFavorite
                  ? "Verwijderen uit favorieten"
                  : "Toevoegen aan favorieten"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* INFORMATION */}
      <Box m="20px 0">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="OMSCHRIJVING" value="description" />
          <Tab label="DETAILS" value="details" />
        </Tabs>
      </Box>

      <Box display="flex" flexDirection="column" gap="15px">
        {value === "description" && (
          <div>
            {item?.attributes?.longDescription}
            {item?.attributes?.videoUrl && (
              <Box mt="20px">
                <VideoPlayer videoUrl={item.attributes.videoUrl} />
              </Box>
            )}
          </div>
        )}

        {value === "details" && (
          <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
            {item?.attributes?.category && (
              <li>
                Categorie:{" "}
                {item.attributes.category
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </li>
            )}
            {item?.attributes?.weightGram && (
              <li>Kruitgewicht: {item.attributes.weightGram} gram</li>
            )}
            {item?.attributes?.kaliber && (
              <li>Kaliber: {item.attributes.kaliber}</li>
            )}
            {item?.attributes?.amount && (
              <li>Aantal stuks: {item.attributes.amount}</li>
            )}
            {item?.attributes?.countFreeFireworks !== undefined && (
              <li>
                Telt mee voor gratis vuurwerk:{" "}
                {item.attributes.countFreeFireworks ? "Ja" : "Nee"}
              </li>
            )}
          </ul>
        )}
      </Box>

      {/* RELATED ITEMS */}
      <Box mt="50px" width="100%">
        <Typography variant="h3" fontWeight="bold">
          Deze vind je misschien ook leuk!
        </Typography>
        <Box
          mt="20px"
          display="flex"
          flexWrap="wrap"
          columnGap="1.33%"
          justifyContent="space-between"
        >
          {relatedItems.slice(0, 4).map((relatedItem) => (
            <Item key={relatedItem.id} item={relatedItem} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ItemDetails);

function VideoPlayer({ videoUrl }) {
  let videoId = videoUrl.split("v=")[1];
  const ampersandPosition = videoId.indexOf("&");
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }

  const isMobile = window.innerWidth <= 760;
  const opts = {
    height: isMobile ? "auto" : "390",
    width: isMobile ? "100%" : "640",
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
}
