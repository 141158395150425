// src/scenes/ResumeOrderPage.jsx

import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart, clearCart } from "../state"; // Importeer clearCart
import { fetcher2 } from "../api";
import { CircularProgress, Box, Typography } from "@mui/material";

const ResumeOrderPage = () => {
  console.log("ResumeOrderPage component wordt gerenderd");

  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasFetchedRef = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (hasFetchedRef.current) return;
    hasFetchedRef.current = true;

    const fetchOrderAndAddToCart = async () => {
      try {
        console.log("Start fetchOrderAndAddToCart");
        console.log("Order ID:", orderId);

        // Haal de bestelling op via de API met de products populatie
        const endpoint = `webshop-orders/${orderId}?populate=*`;
        console.log("Fetching order from endpoint:", endpoint);

        const data = await fetcher2(endpoint);
        console.log("Order data:", data);

        if (!data || !data.data) {
          // Bestelling niet gevonden
          console.error("Order not found");
          navigate("/");
          return;
        }

        const order = data.data.attributes;
        console.log("Order attributes:", order);

        if (!order.products || order.products.length === 0) {
          // Geen producten in de bestelling
          console.error("Order has no products");
          navigate("/");
          return;
        }

        // **Maak de winkelwagen leeg**
        dispatch(clearCart());
        console.log("Winkelwagen geleegd.");

        // Voeg elk product toe aan de winkelwagen
        for (const product of order.products) {
          console.log("Processing product:", product);

          // Haal productdetails op
          const productEndpoint = `items/${product.id}?populate=*`;
          console.log("Fetching product from endpoint:", productEndpoint);

          const productData = await fetcher2(productEndpoint);
          console.log("Product data:", productData);

          if (productData && productData.data) {
            dispatch(
              addToCart({
                item: {
                  id: productData.data.id,
                  attributes: productData.data.attributes,
                  count: product.count,
                },
              })
            );
            console.log(`Product ${productData.data.id} added to cart.`);
          } else {
            // Product niet gevonden, eventueel melding geven
            console.error(`Product met ID ${product.id} niet gevonden.`);
          }
        }

        // Navigeren naar de checkout-pagina na een kleine vertraging om de gebruiker de kans te geven het bericht te lezen
        setTimeout(() => {
          console.log("Navigating to /checkout");
          navigate("/checkout");
        }, 2000); // 2 seconden vertraging
      } catch (error) {
        console.error("Fout bij het herstellen van de bestelling:", error);
        navigate("/");
      } finally {
        setLoading(false); // Zet laadstatus op false
      }
    };

    fetchOrderAndAddToCart();
  }, [orderId, dispatch, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="70vh"
    >
      {loading ? (
        <>
          <CircularProgress />
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            We voegen de items van je onvoltooide bestelling toe aan je
            winkelwagentje, een ogenblik geduld alsjeblieft...
          </Typography>
        </>
      ) : (
        <Typography variant="h6" align="center">
          Klaar! Je wordt doorgestuurd naar de checkout...
        </Typography>
      )}
    </Box>
  );
};

export default ResumeOrderPage;
