import CookieWall from "./components/CookieWall";
import CookieSettingsModal from "./components/CookieSettingsModal"; // Zorg ervoor dat dit de juiste naam heeft
import { AuthProvider } from "./AuthContext";
import AuthModal from "./components/AuthModal";
import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Home from "./scenes/home/Home";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/global/Footer";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import CartMenu from "./scenes/global/CartMenu";
import Checkout from "./scenes/checkout/Checkout";
import Checkout2 from "./scenes/checkout/Checkout2";
import Confirmation from "./scenes/checkout/Confirmation";
import Confirmation2 from "./scenes/checkout/Confirmation2";
import Products from "./scenes/products/Products";
import Products2 from "./scenes/products/Products2";
import Favorites from "./scenes/favorites/Favorites";
import ResumeOrderPage from "./scenes/ResumeOrderPage";
import SearchResults from "./scenes/searchResults/SearchResults";
import PrivacyPolicy from "./scenes/PrivacyPolicy";
import VerkoopVoorwaarden from "./scenes/VerkoopVoorwaarden";
import OrdersPage from "./components/OrdersPage";
import ProfilePage from "./components/ProfilePage";
import Veiligheidsinstructies from "./components/VeiligheidsInstructies";
import GratisVuurwerk from "./components/GratisVuurwerk";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const checkAndRedirectToNewSearch = () => {
  const currentURL = window.location.href;

  // Controleer of de URL het oude zoekpad bevat "/catalogsearch/result/"
  if (currentURL.includes("/catalogsearch/result/")) {
    const url = new URL(currentURL);

    // Haal de query parameter op (bijv. 'atomic+stars')
    const searchParams = url.searchParams.get("q");

    if (searchParams) {
      // Bouw de nieuwe URL op voor de zoekpagina
      const newURL = `/search?query=${searchParams.replace(/\+/g, "%20")}`;

      // Gebruik history API om naar de nieuwe URL te navigeren
      window.history.replaceState(null, null, newURL);
      window.location.reload(); // Pagina herladen om de nieuwe URL te gebruiken
    }
  }
};

function App({ isDarkMode, setIsDarkMode }) {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isCookieSettingsOpen, setCookieSettingsOpen] = useState(false);

  const openAuthModal = () => setIsAuthModalOpen(true);
  const closeAuthModal = () => setIsAuthModalOpen(false);

  const openCookieSettingsModal = () => setCookieSettingsOpen(true);
  const closeCookieSettingsModal = () => setCookieSettingsOpen(false);

  useEffect(() => {
    checkAndRedirectToNewSearch();
  }, []);

  return (
    <div className="app">
      <AuthProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* Redirect routes */}
            <Route
              path="/bruchemvuurwerk/assortiment"
              element={<Navigate to="/products" replace />}
            />
            <Route
              path="/bruchemvuurwerk/overons-dealer"
              element={<Navigate to="/about" replace />}
            />
            <Route
              path="/bruchemvuurwerk/bestel-informatie-dealer"
              element={<Navigate to="/verkoopvoorwaarden" replace />}
            />
            <Route
              path="/bruchemvuurwerk/*"
              element={<Navigate to="/" replace />}
            />

            {/* Standaard routes */}
            <Route
              path="/verkoopvoorwaarden"
              element={<VerkoopVoorwaarden />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Home />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout2" element={<Checkout2 />} />
            <Route
              path="checkout/success/:webshopOrderNumber?"
              element={<Confirmation />}
            />
            <Route
              path="checkout/success2/:sessionId?"
              element={<Confirmation2 />}
            />
            <Route path="/shoppinglist" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/products2" element={<Products2 />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/resume-order/:orderId"
              element={<ResumeOrderPage />}
            />
            <Route path="/orders" element={<OrdersPage />} />
            <Route
              path="/veiligheidsinstructies"
              element={<Veiligheidsinstructies />}
            />
            <Route path="/gratis-vuurwerk" element={<GratisVuurwerk />} />
          </Routes>
          <CartMenu />
          <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />
          <Navbar
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            onAuthClick={openAuthModal}
          />
          <CookieSettingsModal
            isOpen={isCookieSettingsOpen}
            onClose={closeCookieSettingsModal}
          />
          <Footer onCookieSettingsClick={openCookieSettingsModal} />
        </BrowserRouter>
      </AuthProvider>
      <CookieWall onSettingsClick={openCookieSettingsModal} />{" "}
      {/* CookieWall met toegang tot de modal */}
    </div>
  );
}

export default App;
