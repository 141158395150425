// src/components/Checkout.jsx

import React, { useState, useEffect, useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Grid,
  useMediaQuery,
  Tooltip,
  Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { BASE_URL, fetcher2 } from "../../api";
import {
  addToCart,
  increaseCount,
  removeFromCart,
  decreaseCount,
  setCount,
  setStockAlerts,
  clearStockAlert,
} from "../../state";

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Toegevoegd: StrikethroughPrice styled component
const StrikethroughPrice = styled(Typography)`
  text-decoration: line-through;
  color: #aaa;
  margin-right: 10px;
`;

const Checkout = () => {
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState({
    type: "",
    value: 0,
    minOrderAmount: 0,
  });
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [onlinePaymentMethod, setOnlinePaymentMethod] = useState("ideal");
  const [cashCode, setCashCode] = useState("");
  const [userInputCashCode, setUserInputCashCode] = useState("");
  const [cashAccepted, setCashAccepted] = useState(false);
  const [confirmAge, setConfirmAge] = useState(false); // Nieuw
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState("");
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [email, setEmail] = useState(userData?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  const [streetName, setStreetName] = useState(userData?.streetName || "");
  const [houseNumber, setHouseNumber] = useState(userData?.houseNumber || "");
  const [postalCode, setPostalCode] = useState(userData?.postalCode || "");
  const [houseNumberSuffix, setHouseNumberSuffix] = useState(
    userData?.houseNumberSuffix || ""
  );
  const [city, setCity] = useState(userData?.city || "");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [pickupMoments, setPickupMoments] = useState([]);
  const [tosAgreed, setTosAgreed] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState(0);
  const [subtotalAfterDiscount, setSubtotalAfterDiscount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0); // Toegevoegd
  const [orderError, setOrderError] = useState("");
  const [errors, setErrors] = useState({});
  const [discountError, setDiscountError] = useState("");
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const stockAlerts = useSelector((state) => state.cart.stockAlerts); // Nieuwe lijn
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [showOrderDetails, setShowOrderDetails] = useState(!isMobile);

  // ====== Toegevoegd ======
  const [token, setToken] = useState("");
  // =========================

  // ====== Toegevoegd: Functie om voorraad te controleren ======
  const fetchStockQuantity = async (itemId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/items/${itemId}`);
      const data = await response.json();
      return data.data.attributes.stockQuantity;
    } catch (error) {
      console.error("Fout bij het ophalen van voorraad:", error);
      return -1;
    }
  };
  // ==============================================================

  // ====== Toegevoegd: useFreeItem Hook ======
  const FREE_ITEMS_START_DATE = new Date(
    process.env.REACT_APP_FREE_ITEMS_START_DATE
  );
  const API_BASE_URL = BASE_URL;

  const useFreeItem = (totalPriceForFreeItems) => {
    const [freeItem, setFreeItem] = useState(null);
    const [alwaysFreeItems, setAlwaysFreeItems] = useState([]);

    // Fetch always free items (freeFromPrice = 0)
    useEffect(() => {
      const fetchAlwaysFreeItems = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$eq]=0`
          );
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            setAlwaysFreeItems(data.data);
          }
        } catch (error) {
          console.error("Fout bij het ophalen van always free items:", error);
        }
      };
      fetchAlwaysFreeItems();
    }, []);

    // Fetch free items based on the total price
    useEffect(() => {
      const fetchFreeItem = async () => {
        if (new Date() >= FREE_ITEMS_START_DATE) {
          try {
            const response = await fetch(
              `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$lte]=${totalPriceForFreeItems}`
            );
            const data = await response.json();
            if (data && data.data && Array.isArray(data.data)) {
              const eligibleItems = data.data.filter(
                (item) =>
                  item.attributes &&
                  item.attributes.freeFromPrice <= totalPriceForFreeItems &&
                  item.attributes.freeFromPrice !== 0
              );
              if (eligibleItems.length) {
                eligibleItems.sort(
                  (a, b) =>
                    b.attributes.freeFromPrice - a.attributes.freeFromPrice
                );
                setFreeItem(eligibleItems[0]);
              } else {
                setFreeItem(null);
              }
            }
          } catch (error) {
            console.error("Fout bij het ophalen van free items:", error);
          }
        } else {
          setFreeItem(null);
        }
      };
      fetchFreeItem();
    }, [totalPriceForFreeItems]);

    return [freeItem, alwaysFreeItems];
  };
  // ======================================================================

  useEffect(() => {
    const loadPickupMoments = async () => {
      try {
        const data = await fetcher2(
          "pickup-moments?populate=excludedArtclesFromTimeSlots"
        );
        setPickupMoments(data.data);
      } catch (error) {
        console.error("Error fetching pickup moments", error);
      }
    };
    loadPickupMoments();
  }, []);

  // ====== Toegevoegd: useEffect voor voorraadcontrole ======
  useEffect(() => {
    const checkStock = async () => {
      const alerts = {};
      for (const item of cart) {
        const currentStock = await fetchStockQuantity(item.id);
        if (currentStock < 0) {
          alerts[
            item.id
          ] = `Kon voorraad niet controleren voor ${item.attributes.name}.`;
          continue;
        }
        if (item.count > currentStock) {
          dispatch(setCount({ id: item.id, count: currentStock }));
          alerts[
            item.id
          ] = `Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`;
        }
      }
      if (Object.keys(alerts).length > 0) {
        dispatch(setStockAlerts(alerts));
      }
    };

    if (cart.length > 0) {
      checkStock();
    }
  }, [cart, dispatch]);
  // ===========================================================

  // ====== Toegevoegd: Initiale voorraadcontrole bij component mount ======
  useEffect(() => {
    const initialCheckStock = async () => {
      const alerts = {};
      for (const item of cart) {
        const currentStock = await fetchStockQuantity(item.id);
        if (currentStock < 0) {
          alerts[
            item.id
          ] = `Kon voorraad niet controleren voor ${item.attributes.name}.`;
          continue;
        }
        if (item.count > currentStock) {
          alerts[
            item.id
          ] = `Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`;
          dispatch(setCount({ id: item.id, count: currentStock }));
        }
      }
      if (Object.keys(alerts).length > 0) {
        dispatch(setStockAlerts(alerts));
      }
    };

    if (cart.length > 0) {
      initialCheckStock();
    }
  }, [cart, dispatch]);
  // ======================================================================

  // Definieer handleRemoveDiscountCode met useCallback
  const handleRemoveDiscountCode = useCallback(() => {
    if (discount.type === "freeProduct") {
      // Zoek het gratis product en verminder de hoeveelheid of verwijder het
      const freeProduct = cart.find((item) => item.id === discount.value);
      if (freeProduct) {
        if (freeProduct.count > 1) {
          dispatch(decreaseCount({ id: discount.value }));
        } else {
          dispatch(removeFromCart({ id: discount.value }));
        }
      }
    }
    // Reset de discount state
    setDiscount({
      type: "",
      value: 0,
      minOrderAmount: 0,
    });
    setDiscountCode("");
    setDiscountApplied(false);
    setDiscountError("");

    // Clear the corresponding stock alert if any
    dispatch(clearStockAlert([discount.value]));
  }, [dispatch, cart, discount.value, discount.type]);

  // **Nieuwe useEffect om kortingscode te verwijderen bij overschakeling naar 'contant'**
  useEffect(() => {
    if (paymentMethod === "contant" && discountApplied) {
      handleRemoveDiscountCode();
      setDiscountError(
        "Kortingscode is verwijderd. De kortingscode is alleen geldig bij online betalingen."
      );
    }
  }, [paymentMethod, discountApplied, handleRemoveDiscountCode]);

  // **Cleanup Effect om kortingscode te verwijderen bij component unmount**
  useEffect(() => {
    return () => {
      if (discountApplied) {
        handleRemoveDiscountCode();
      }
    };
  }, [discountApplied, handleRemoveDiscountCode]);

  const validate = () => {
    let tempErrors = {};

    console.log("Start validatie...");

    tempErrors.firstName = firstName ? "" : "Voornaam is verplicht";
    tempErrors.lastName = lastName ? "" : "Achternaam is verplicht";
    tempErrors.email = email
      ? /\S+@\S+\.\S+/.test(email)
        ? ""
        : "Ongeldig e-mailadres"
      : "E-mail is verplicht";
    tempErrors.phoneNumber = phoneNumber
      ? /^\+?[0-9]+$/.test(phoneNumber)
        ? ""
        : "Ongeldig telefoonnummer"
      : "Telefoonnummer is verplicht";
    tempErrors.streetName = streetName ? "" : "Straatnaam is verplicht";
    tempErrors.city = city ? "" : "Woonplaats is verplicht";
    tempErrors.postalCode = postalCode
      ? /^\d{4}\s?[a-zA-Z]{2}$/.test(postalCode)
        ? ""
        : "Postcode moet 4 cijfers en 2 letters bevatten"
      : "Postcode is verplicht";
    tempErrors.houseNumber = houseNumber
      ? /^\d+$/.test(houseNumber)
        ? ""
        : "Huisnummer mag alleen nummers bevatten"
      : "Huisnummer is verplicht";
    tempErrors.pickupDate = pickupDate ? "" : "Ophaaldatum is verplicht";
    tempErrors.pickupTime = pickupTime ? "" : "Afhaaltijd is verplicht";
    tempErrors.tosAgreed = tosAgreed
      ? ""
      : "Je moet akkoord gaan met de algemene voorwaarden en privacy policy";

    if (paymentMethod === "online" && !onlinePaymentMethod) {
      tempErrors.onlinePaymentMethod = "Selecteer een online betaalmethode";
    }

    // Validatie voor minimale bestelwaarde voor alle betalingsmethoden
    if (totalPrice < 25) {
      tempErrors.minOrderAmount = "De minimale bestelwaarde is €25.";
    }

    if (paymentMethod === "contant") {
      console.log("Geheime code opgehaald:", cashCode);
      console.log("Gebruiker ingevoerde code:", userInputCashCode);

      tempErrors.cashCode =
        userInputCashCode === cashCode
          ? ""
          : "De ingevoerde code is onjuist. Neem contact met ons op.";
      tempErrors.cashAccepted = cashAccepted
        ? ""
        : "Je moet akkoord gaan met de betalingsvoorwaarden.";

      // **Toegevoegde validatie voor leeftijdsbevestiging**
      tempErrors.confirmAge = confirmAge
        ? ""
        : "Je moet bevestigen dat je 18 jaar of ouder bent.";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const formatPickupDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("nl-NL", {
      weekday: "long",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };

  const formatPickupTime = (isoTime) => {
    return isoTime.substring(0, 5);
  };

  const getTimesForDate = (date) => {
    const cartArtNumbers = cart.map((item) =>
      String(item.attributes.artNumber)
    );
    return pickupMoments
      .filter((moment) => formatPickupDate(moment.attributes.date) === date)
      .map((moment) => {
        const currentPickups = moment.attributes.currentPickups || 0;
        const maxPickups = moment.attributes.maxPickups || Infinity;
        const excludedArticles =
          moment.attributes.excludedArtclesFromTimeSlots || [];
        const isExcluded = cartArtNumbers.some((artNumber) =>
          excludedArticles.map(String).includes(artNumber)
        );
        return {
          time: formatPickupTime(moment.attributes.time),
          available: currentPickups < maxPickups && !isExcluded,
        };
      })
      .filter((timeSlot) => timeSlot.available);
  };

  const handleDateChange = (e) => {
    setPickupDate(e.target.value);
    setPickupTime("");
  };

  const handleTimeChange = (e) => {
    setPickupTime(e.target.value);
  };

  const toggleDiscountCodeInput = () => {
    setShowDiscountCodeInput(!showDiscountCodeInput);
    setDiscountError("");
  };

  const generateOrderNumber = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds())
      .padStart(3, "0")
      .substr(0, 2);
    const randomTwoDigits = String(Math.floor(Math.random() * 100)).padStart(
      2,
      "0"
    );
    return `${day}${milliseconds}${randomTwoDigits}`;
  };

  const handleConfirmOrder = async () => {
    if (isSubmitting) return;

    if (!validate()) return;

    // Nieuwe voorraadcontrole voordat de bestelling wordt bevestigd
    const alerts = {};
    for (const item of cart) {
      const currentStock = await fetchStockQuantity(item.id);
      if (currentStock < 0) {
        alerts[
          item.id
        ] = `Kon voorraad niet controleren voor ${item.attributes.name}.`;
        continue;
      }
      if (item.count > currentStock) {
        alerts[
          item.id
        ] = `Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`;
        dispatch(setCount({ id: item.id, count: currentStock }));
      }
    }
    if (Object.keys(alerts).length > 0) {
      dispatch(setStockAlerts(alerts));
      return; // Stop de order bevestiging
    }

    setIsSubmitting(true);
    setDiscountError("");
    setOrderError("");
    console.log("Start orderbevestiging...");

    const orderNumber = generateOrderNumber();
    const formattedNotes = notes.replace(/\n/g, "<br>");
    const requestBody = {
      tosAgreed: tosAgreed,
      email: email,
      phoneNumber: phoneNumber,
      userName: `${firstName} ${lastName}`,
      webshopOrderNumber: orderNumber,
      products: cart.map(({ id, count }) => ({ id, count })),
      notes: formattedNotes,
      streetName: streetName,
      houseNumber: houseNumber,
      houseNumberSuffix: houseNumberSuffix || undefined,
      postalCode: postalCode,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      city: city,
      discountCode: discountCode,
      paymentMethod: paymentMethod,
      paymentStatus: paymentMethod === "contant" ? "contant" : "open",
      onlinePaymentMethod:
        paymentMethod === "online" ? onlinePaymentMethod : undefined,
    };

    console.log("Order payload:", requestBody);

    try {
      const jsonResponse = await fetcher2("webshop-orders", "POST", {
        data: requestBody,
      });

      console.log("Order response:", jsonResponse);

      if (paymentMethod === "contant") {
        navigate(`/checkout/success/${orderNumber}`);
      } else if (jsonResponse && jsonResponse.paymentUrl) {
        window.location.href = jsonResponse.paymentUrl;
      } else {
        console.error("Geen betalingslink ontvangen.");
        setOrderError(
          "Er is een probleem opgetreden bij het verwerken van je bestelling."
        );
      }
    } catch (error) {
      console.error("Fout bij bestelling", error);
      setOrderError(
        "Er is een probleem opgetreden bij het verwerken van je bestelling."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // ====== Toegevoegd: Bereken totalPriceForFreeItems en gebruik useFreeItem ======
  const totalPriceForFreeItems = parseFloat(
    cart
      .filter((item) => item.attributes.countFreeFireworks === true)
      .reduce((total, item) => {
        const productPrice = item.attributes.priceDiscount
          ? item.attributes.priceDiscount
          : item.attributes.price;
        return total + item.count * productPrice;
      }, 0)
      .toFixed(2)
  );

  const [freeItem, alwaysFreeItems] = useFreeItem(totalPriceForFreeItems);
  // ============================================================================

  useEffect(() => {
    // Bereken prijs vóór korting
    let calculatedPriceBeforeDiscount = cart.reduce((total, item) => {
      const productPrice =
        item.attributes.priceDiscount != null
          ? item.attributes.priceDiscount
          : item.attributes.price;
      return total + item.count * productPrice;
    }, 0);

    setPriceBeforeDiscount(
      parseFloat(calculatedPriceBeforeDiscount.toFixed(2))
    );

    // Controleer of de prijs voldoet aan de minimale bestelwaarde
    if (
      discount.type &&
      calculatedPriceBeforeDiscount < discount.minOrderAmount
    ) {
      handleRemoveDiscountCode();
      setDiscountError(
        "De kortingscode is niet geldig omdat de bestelwaarde nu te laag is."
      );
    }

    // Pas korting toe indien van toepassing
    let discountedPrice = calculatedPriceBeforeDiscount;
    if (discount.type === "discountPercentage") {
      discountedPrice *= 1 - discount.value / 100;
    } else if (discount.type === "discountPrice") {
      discountedPrice -= discount.value;
      if (discountedPrice < 0) discountedPrice = 0;
    }
    // FreeProduct wordt niet meer in de frontend afgetrokken

    // Bereken subtotaal na korting (exclusief transactiekosten)
    const calculatedSubtotalAfterDiscount = parseFloat(
      discountedPrice.toFixed(2)
    );
    setSubtotalAfterDiscount(calculatedSubtotalAfterDiscount);

    // Bereken transactiekosten
    let fee = 0;
    if (paymentMethod === "online") {
      if (onlinePaymentMethod === "paypal") {
        fee = 0.5; // Vaste kosten voor PayPal
      }
      // Voeg hier andere betaalmethoden en hun transactiekosten toe indien nodig
    }
    setTransactionFee(fee);

    // Bereken totaalprijs inclusief transactiekosten
    setTotalPrice(calculatedSubtotalAfterDiscount + fee);
  }, [
    cart,
    discount,
    paymentMethod,
    onlinePaymentMethod,
    handleRemoveDiscountCode,
  ]);

  const handleSubmitDiscountCode = async () => {
    if (discountApplied) {
      setDiscountError(
        "Je kunt slechts één kortingscode per bestelling toepassen."
      );
      return;
    }

    if (!discountCode.trim()) {
      setDiscountError("Vul een kortingscode in.");
      return;
    }

    setDiscountError("");
    try {
      const endpoint = `discount-codes?filters[code]=${discountCode}`;
      const data = await fetcher2(endpoint);

      if (data.data && data.data.length > 0) {
        const discountCodeData = data.data[0].attributes;
        const currentDate = new Date();
        const endDate = new Date(discountCodeData.endDate);

        if (currentDate > endDate) {
          setDiscountError("Kortingscode is verlopen.");
          return;
        }

        const minOrderAmount = discountCodeData.minOrderAmount || 0;

        if (priceBeforeDiscount < minOrderAmount) {
          setDiscountError(
            `De minimale bestelwaarde voor deze kortingscode is €${minOrderAmount}.`
          );
          return;
        }

        if (discountCodeData.discountType === "discountPercentage") {
          const discountPercentage =
            discountCodeData.discountPercentage || discountCodeData.value;
          if (
            typeof discountPercentage !== "number" ||
            isNaN(discountPercentage)
          ) {
            setDiscountError("Ongeldige kortingspercentage waarde.");
            return;
          }
          setDiscount({
            type: "discountPercentage",
            value: discountPercentage,
            minOrderAmount: minOrderAmount,
          });
          setDiscountApplied(true);
        } else if (discountCodeData.discountType === "discountPrice") {
          const discountPrice =
            discountCodeData.discountPrice || discountCodeData.value;
          if (typeof discountPrice !== "number" || isNaN(discountPrice)) {
            setDiscountError("Ongeldige kortingsprijs waarde.");
            return;
          }
          if (discountPrice > priceBeforeDiscount) {
            setDiscountError(
              "Het kortingsbedrag is hoger dan het subtotaal van je bestelling."
            );
            return;
          }
          setDiscount({
            type: "discountPrice",
            value: discountPrice,
            minOrderAmount: minOrderAmount,
          });
          setDiscountApplied(true);
        } else if (discountCodeData.discountType === "freeProduct") {
          const freeProductId = discountCodeData.value;
          if (!freeProductId) {
            setDiscountError("Ongeldig gratis product ID.");
            return;
          }

          const existingProduct = cart.find(
            (item) => item.id === freeProductId
          );
          if (existingProduct) {
            dispatch(increaseCount({ id: freeProductId }));
          } else {
            try {
              const productResponse = await fetcher2(`items/${freeProductId}`);
              if (productResponse.data) {
                const productData = productResponse.data.attributes;
                dispatch(
                  addToCart({
                    item: {
                      id: freeProductId,
                      attributes: {
                        ...productData,
                        priceDiscount: 0, // Zet de prijs op 0 voor gratis product
                      },
                      count: 1,
                    },
                  })
                );
              } else {
                setDiscountError(
                  "Het gratis product kon niet worden gevonden."
                );
                return;
              }
            } catch (error) {
              console.error(
                "Fout bij het ophalen van het gratis product:",
                error
              );
              setDiscountError(
                "Er is een fout opgetreden bij het toevoegen van het gratis product."
              );
              return;
            }
          }

          setDiscount({
            type: "freeProduct",
            value: freeProductId,
            minOrderAmount: minOrderAmount,
          });
          setDiscountApplied(true);
        }
      } else {
        setDiscountError("Kortingscode is niet geldig.");
      }
    } catch (error) {
      console.error("Fout bij kortingscodevalidatie", error);
      setDiscountError(
        "Er is een fout opgetreden bij het valideren van de kortingscode."
      );
    }
  };

  return (
    <Box width="80%" m="60px auto">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={{ pr: 3 }}>
          <Typography variant="h3" gutterBottom>
            Uw Bestelling
          </Typography>
          <Box>
            <Card variant="outlined" sx={{ mt: 2, ml: 3 }}>
              <CardActions>
                <IconButton
                  onClick={() => setShowOrderDetails(!showOrderDetails)}
                  size="small"
                >
                  {showOrderDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <Typography component="span">Bestelling Details</Typography>
              </CardActions>
              {showOrderDetails && (
                <CardContent>
                  {cart.map((item) => (
                    <Box key={`${item.attributes.name}-${item.id}`}>
                      <FlexBox p="15px 0">
                        <Box flex="1 1 20%">
                          <img
                            alt={item?.attributes?.name}
                            width="60%"
                            height="auto"
                            src={`${BASE_URL}${
                              item?.attributes?.image?.data?.attributes?.formats
                                ?.medium?.url ||
                              item?.attributes?.image?.data?.attributes?.url
                            }`}
                            style={{ objectFit: "contain" }}
                          />
                        </Box>
                        <Box flex="1 1 80%">
                          <FlexBox mb="5px">
                            <Typography fontWeight="bold">
                              {item.attributes.name}
                            </Typography>
                            {/* Toon een verwijderknop voor de kortingscode indien van toepassing */}
                            {discountApplied && item.id === discount.value && (
                              <IconButton
                                onClick={handleRemoveDiscountCode}
                                aria-label="Verwijder kortingscode"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </FlexBox>
                          <Typography>
                            {item.attributes.shortDescription}
                          </Typography>
                          <FlexBox m="15px 0">
                            <Typography>{item.count} stuks </Typography>
                            <Typography fontWeight="bold">
                              €
                              {(
                                item.count *
                                (item.attributes.priceDiscount != null
                                  ? item.attributes.priceDiscount
                                  : item.attributes.price)
                              ).toFixed(2)}
                            </Typography>
                          </FlexBox>
                        </Box>
                      </FlexBox>

                      {/* ====== Toegevoegd: Voorraadalert per item ====== */}
                      {stockAlerts[item.id] && (
                        <Alert
                          severity="warning"
                          onClose={() => {
                            dispatch(clearStockAlert({ id: item.id })); // Aangepaste regel
                          }}
                          sx={{ mb: 2 }}
                        >
                          {stockAlerts[item.id]}
                        </Alert>
                      )}

                      {/* ================================================ */}

                      <Divider />
                    </Box>
                  ))}

                  {/* Weergeven van Altijd Gratis Producten */}
                  {alwaysFreeItems.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="h6">
                        Altijd Gratis Producten
                      </Typography>
                      {alwaysFreeItems.map((item) => (
                        <Box key={`always-free-${item.id}`}>
                          <FlexBox p="15px 0">
                            <Box flex="1 1 20%">
                              <img
                                alt={item?.attributes?.name}
                                width="60%"
                                height="auto"
                                src={`${BASE_URL}${
                                  item?.attributes?.image?.data?.attributes
                                    ?.formats?.medium?.url ||
                                  item?.attributes?.image?.data?.attributes?.url
                                }`}
                                style={{ objectFit: "contain" }}
                              />
                            </Box>
                            <Box flex="1 1 80%">
                              <FlexBox mb="5px">
                                <Typography fontWeight="bold">
                                  {item.attributes.name} (Gratis)
                                </Typography>
                                <Tooltip title="Kan niet verwijderd worden">
                                  <IconButton disabled>
                                    <CloseIcon />
                                  </IconButton>
                                </Tooltip>
                              </FlexBox>
                              <Typography>
                                {item.attributes.shortDescription}
                              </Typography>
                              <FlexBox m="15px 0">
                                <Typography>1 stuk </Typography>
                                <StrikethroughPrice>
                                  €
                                  {parseFloat(item.attributes.price).toFixed(2)}
                                </StrikethroughPrice>
                              </FlexBox>
                            </Box>
                          </FlexBox>
                          <Divider />
                        </Box>
                      ))}
                    </Box>
                  )}

                  {/* Weergeven van Gratis Item op Basis van Bestelwaarde */}
                  {freeItem && (
                    <Box mt={2}>
                      <Typography variant="h6">
                        Gratis Producten op basis van je bestelbedrag
                      </Typography>
                      <FlexBox p="15px 0">
                        <Box flex="1 1 20%">
                          <img
                            alt={freeItem.attributes.name}
                            width="60%"
                            height="auto"
                            src={`${BASE_URL}${
                              freeItem?.attributes?.image?.data?.attributes
                                ?.formats?.medium?.url ||
                              freeItem?.attributes?.image?.data?.attributes?.url
                            }`}
                            style={{ objectFit: "contain" }}
                          />
                        </Box>
                        <Box flex="1 1 80%">
                          <FlexBox mb="5px">
                            <Typography fontWeight="bold">
                              {freeItem.attributes.name} (Gratis)
                            </Typography>
                            <Tooltip title="Kan niet verwijderd worden">
                              <IconButton disabled>
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </FlexBox>
                          <Typography>
                            {freeItem.attributes.shortDescription}
                          </Typography>
                          <FlexBox m="15px 0">
                            <Typography>1 stuk </Typography>
                            <StrikethroughPrice>
                              €
                              {parseFloat(freeItem.attributes.price).toFixed(2)}
                            </StrikethroughPrice>
                          </FlexBox>
                        </Box>
                      </FlexBox>
                      <Divider />
                    </Box>
                  )}
                </CardContent>
              )}

              {/* Subtotaal */}
              <FlexBox justifyContent="space-between" width="100%" p={2}>
                <Typography>Subtotaal:</Typography>
                <Typography>€{priceBeforeDiscount.toFixed(2)}</Typography>
              </FlexBox>

              {/* Subtotaal na korting */}
              {(discount.type === "discountPercentage" ||
                discount.type === "discountPrice" ||
                discount.type === "freeProduct") && (
                <FlexBox justifyContent="space-between" width="100%" p={2}>
                  <Typography>Subtotaal na korting:</Typography>
                  <Typography>€{subtotalAfterDiscount.toFixed(2)}</Typography>
                </FlexBox>
              )}

              {/* Transactiekosten */}
              {paymentMethod === "online" && (
                <FlexBox justifyContent="space-between" width="100%" p={2}>
                  <Typography>Transactiekosten:</Typography>
                  <Typography>€{transactionFee.toFixed(2)}</Typography>
                </FlexBox>
              )}

              {/* Totaalprijs (inclusief transactiekosten) */}
              <FlexBox justifyContent="space-between" width="100%" p={2}>
                <Typography variant="h6">
                  Totaalprijs
                  {paymentMethod === "online"
                    ? " (inclusief transactiekosten)"
                    : ""}
                  :
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  €{totalPrice.toFixed(2)}
                </Typography>
              </FlexBox>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Uw Gegevens
          </Typography>
          <Box sx={{ pl: 3, pr: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.firstName)}
                  name="firstName"
                  autoComplete="given-name"
                  helperText={errors.firstName}
                  label="Voornaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.lastName)}
                  name="lastName"
                  autoComplete="family-name"
                  helperText={errors.lastName}
                  label="Achternaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.email)}
                  name="email"
                  autoComplete="email"
                  helperText={errors.email}
                  label="E-mail"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.phoneNumber)}
                  name="tel"
                  autoComplete="tel"
                  helperText={errors.phoneNumber}
                  label="Telefoonnummer"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.streetName)}
                  helperText={errors.streetName}
                  name="street-address"
                  autoComplete="street-address"
                  label="Straatnaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(errors.houseNumber)}
                  helperText={errors.houseNumber}
                  label="Huisnummer"
                  name="house-number"
                  autoComplete="address-line2"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Toevoeging"
                  name="house-number-suffix"
                  autoComplete="address-line3"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={houseNumberSuffix}
                  onChange={(e) => setHouseNumberSuffix(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(errors.postalCode)}
                  helperText={errors.postalCode}
                  name="postal-code"
                  autoComplete="postal-code"
                  label="Postcode"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  error={Boolean(errors.city)}
                  helperText={errors.city}
                  name="address-level2"
                  autoComplete="address-level2"
                  label="Woonplaats"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  {pickupMoments.length === 0 ? (
                    <Typography color="error">
                      Geen datums beschikbaar
                    </Typography>
                  ) : (
                    <>
                      <InputLabel>Ophaaldatum</InputLabel>
                      <Select
                        value={pickupDate}
                        label="Ophaaldatum"
                        onChange={handleDateChange}
                        error={Boolean(errors.pickupDate)}
                      >
                        {Array.from(
                          new Set(
                            pickupMoments.map((moment) =>
                              formatPickupDate(moment.attributes.date)
                            )
                          )
                        ).map((date) => (
                          <MenuItem key={date} value={date}>
                            {date}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  {!pickupDate ? (
                    <Select value="" label="" displayEmpty disabled>
                      <MenuItem disabled value="">
                        Selecteer eerst ophaaldatum
                      </MenuItem>
                    </Select>
                  ) : getTimesForDate(pickupDate).length === 0 ? (
                    <Select value="" label="" displayEmpty disabled>
                      <MenuItem disabled value="">
                        Geen tijdsloten beschikbaar
                      </MenuItem>
                    </Select>
                  ) : (
                    <>
                      <InputLabel>Afhaaltijd</InputLabel>
                      <Select
                        value={pickupTime}
                        label="Afhaaltijd"
                        onChange={handleTimeChange}
                        error={Boolean(errors.pickupTime)}
                        displayEmpty
                      >
                        {getTimesForDate(pickupDate).map((timeSlot) => (
                          <MenuItem key={timeSlot.time} value={timeSlot.time}>
                            {timeSlot.time}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  checked={tosAgreed}
                  onChange={(e) => setTosAgreed(e.target.checked)}
                  name="tosAgreed"
                  color="primary"
                />
              }
              label={
                <Typography>
                  Ik ga akkoord met de{" "}
                  <a
                    href="https://vuurwerkbruchem.com/verkoopvoorwaarden"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    algemene voorwaarden
                  </a>{" "}
                  en{" "}
                  <a
                    href="https://vuurwerkbruchem.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                  .
                </Typography>
              }
            />

            <TextField
              label="Type hier eventuele bijzonderheden"
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="betaalmethode-label">Betaalmethode</InputLabel>
              <Select
                labelId="betaalmethode-label"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                label="Betaalmethode"
              >
                <MenuItem value="online">Online betalen</MenuItem>
                <MenuItem value="contant">
                  Contant betalen (bij afhalen)
                </MenuItem>
              </Select>
            </FormControl>

            {paymentMethod === "online" && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="online-betaalmethode-label">
                    Online betaalmethode
                  </InputLabel>
                  <Select
                    labelId="online-betaalmethode-label"
                    value={onlinePaymentMethod}
                    onChange={(e) => setOnlinePaymentMethod(e.target.value)}
                    label="Online betaalmethode"
                    error={Boolean(errors.onlinePaymentMethod)}
                  >
                    <MenuItem value="ideal">iDEAL</MenuItem>
                    <MenuItem value="paypal">PayPal</MenuItem>
                  </Select>
                  {errors.onlinePaymentMethod && (
                    <Typography color="error" variant="caption">
                      {errors.onlinePaymentMethod}
                    </Typography>
                  )}
                </FormControl>

                {!discountApplied && (
                  <>
                    <Button onClick={toggleDiscountCodeInput}>
                      Klik hier om een kortingscode te verzilveren
                    </Button>
                    {showDiscountCodeInput && (
                      <Box>
                        <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Kortingscode"
                          value={discountCode}
                          onChange={handleDiscountCodeChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleSubmitDiscountCode}>
                                  <CheckCircleIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(discountError)}
                          helperText={discountError}
                        />
                      </Box>
                    )}
                  </>
                )}

                {/* Toegepaste Kortingscode Met Annuleren Optie */}
                {discountApplied && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    bgcolor="#e0f7fa"
                    borderRadius={2}
                    mt={2}
                  >
                    <Typography>
                      Kortingscode "<strong>{discountCode}</strong>" is
                      toegepast.
                    </Typography>
                    <IconButton
                      onClick={handleRemoveDiscountCode}
                      aria-label="Verwijder kortingscode"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}

                {discountApplied && discount.type !== "freeProduct" && (
                  <Typography
                    variant="body2"
                    color="success.main"
                    sx={{ mt: 1 }}
                  >
                    Kortingscode succesvol toegepast!
                  </Typography>
                )}
              </>
            )}

            {paymentMethod === "contant" && (
              <>
                <Box
                  sx={{
                    backgroundColor: "#ffcccb",
                    border: "1px solid #f44336",
                    padding: 2,
                    borderRadius: 2,
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    textAlign: "left",
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    <strong>Let op:</strong> Als je kiest voor contante
                    betaling, ga je een betalingsverplichting aan. Neem contact
                    op met ons via <strong>+31 6 16175368</strong> om je
                    bestelling te bevestigen. Bij dit contact ontvang je een
                    code die je hieronder moet invullen om de bestelling
                    definitief te maken. De betaling kan contant of per pin
                    worden voldaan bij het afhalen van de bestelling.
                    <br />
                    <br />
                    Indien je jouw bestelling niet op het afgesproken moment
                    ophaalt, annuleren wij je bestelling en brengen we €5,00 aan
                    administratiekosten bij je in rekening.
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<FaWhatsapp />}
                    href="https://wa.me/31616175368"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      backgroundColor: "#25D366",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#1ebe57",
                      },
                      mt: 2,
                      textTransform: "none",
                    }}
                  >
                    Appen voor de Code
                  </Button>
                </Box>

                <TextField
                  label="Telefonische Code"
                  value={userInputCashCode}
                  onChange={(e) => setUserInputCashCode(e.target.value)}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.cashCode)}
                  helperText={
                    errors.cashCode ||
                    "Vul hier de code in die je telefonisch hebt ontvangen."
                  }
                />

                {/* Gecombineerd Vinkje */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cashAccepted}
                      onChange={(e) => setCashAccepted(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Ik ga akkoord met de aanvullende voorwaarden voor contante betaling en begrijp dat ik een betalingsverplichting aanga."
                  sx={{ mt: 2 }}
                />

                {/* Leeftijdsbevestiging Vinkje */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmAge}
                      onChange={(e) => setConfirmAge(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Ik bevestig dat ik 18 jaar of ouder ben."
                  sx={{ mt: 1 }}
                />

                {Boolean(errors.cashAccepted) && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {errors.cashAccepted}
                  </Typography>
                )}

                {Boolean(errors.confirmAge) && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {errors.confirmAge}
                  </Typography>
                )}
              </>
            )}

            {/* Foutmelding voor minimale bestelwaarde */}
            {errors.minOrderAmount && (
              <Typography color="error" sx={{ mt: 1 }}>
                {errors.minOrderAmount}
              </Typography>
            )}

            {/* Foutmelding van backend */}
            {orderError && (
              <Box sx={{ mt: 2 }}>
                <Typography color="error">{orderError}</Typography>
              </Box>
            )}

            {/* Boodschap voor minimale bestelwaarde */}
            {totalPrice < 25 && (
              <Typography color="error" sx={{ mt: 1 }}>
                Het minimale bestelbedrag is €25. Voeg meer items toe aan je
                winkelwagen om door te gaan.
              </Typography>
            )}

            {/* Bestelknop */}
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                disabled={
                  isSubmitting ||
                  cart.length === 0 ||
                  totalPrice < 25 ||
                  (paymentMethod === "contant" &&
                    (!cashAccepted || !confirmAge))
                }
                onClick={handleConfirmOrder}
              >
                {paymentMethod === "online"
                  ? "Naar Betalen"
                  : "Bestelling Plaatsen"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
