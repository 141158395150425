import { useState, memo } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Box,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades } from "../theme";
import { addToCart } from "../state";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";

const Item = ({ item, width }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const {
    palette: { neutral },
  } = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { category, price, priceDiscount, name, amount, image, outOfStock } =
    item.attributes;

  const url =
    image?.data?.attributes?.formats?.medium?.url ||
    image?.data?.attributes?.url;

  const handleAddToCart = () => {
    dispatch(addToCart({ item: { ...item, count } }));
  };

  return (
    <Box width={width}>
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        {/* Wrapping the image in an anchor tag */}
        <a href={`/item/${item.id}`} rel="noopener noreferrer">
          <img
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              height: isMobile ? "200px" : "300px",
              cursor: "pointer",
            }}
            alt={name}
            width={isMobile ? "100%" : "300px"}
            height={isMobile ? "200px" : "400px"}
            src={`${BASE_URL}${url}`}
            loading="lazy"
          />
        </a>
        <Box
          display={isMobile || isHovered ? "flex" : "none"}
          position="absolute"
          bottom="10%"
          left="0"
          width="100%"
          padding="0 5%"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            backgroundColor={theme.palette.background.paper}
            border={`1.5px solid ${shades.neutral[300]}`}
            sx={{ padding: "2px", marginRight: isMobile ? "10px" : "0" }}
          >
            <IconButton
              onClick={() => setCount(Math.max(count - 1, 1))}
              disabled={outOfStock}
              sx={{
                padding: "2px",
                color: theme.palette.text.primary, // Gebruik dezelfde kleur als de tekst en border
                backgroundColor: theme.palette.background.paper,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover, // Consistente hover-kleur
                },
              }}
            >
              <RemoveIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>

            <Typography
              color={shades.primary[300]}
              sx={{ fontSize: isMobile ? "0.7rem" : "1rem", margin: "0 4px" }}
            >
              {count}
            </Typography>
            <IconButton
              onClick={() => setCount(count + 1)}
              disabled={outOfStock}
              sx={{
                padding: "2px",
                color: theme.palette.text.primary, // Gebruik dezelfde kleur als de tekst en border
                backgroundColor: theme.palette.background.paper,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover, // Consistente hover-kleur
                },
              }}
            >
              <AddIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Box>
          <Button
            onClick={handleAddToCart}
            sx={{
              backgroundColor: outOfStock
                ? neutral.dark
                : theme.palette.secondary.main, // Gebruik de secundaire kleur voor een betere match met het thema
              color: "white",
              fontSize: isMobile ? "0.5rem" : "1rem",
              padding: isMobile ? "2px 5px" : "8px 16px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark, // Donkerdere variant bij hover
              },
            }}
            disabled={outOfStock}
          >
            {outOfStock ? "Uitverkocht" : "In winkelwagen"}
          </Button>
        </Box>
      </Box>
      <Box mt={isMobile ? "5px" : "3px"}>
        <Typography
          variant="subtitle2"
          color={
            theme.palette.mode === "dark"
              ? theme.palette.text.secondary
              : neutral.dark
          } // Aanpassing voor dark mode
          fontSize={isMobile ? "0.8rem" : "1rem"}
        >
          {category
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())}
        </Typography>

        <Typography fontSize={isMobile ? "0.9rem" : "1rem"}>{name} </Typography>
        {amount && (
          <Typography fontSize={isMobile ? "0.8rem" : "1rem"}>
            {amount} stuks
          </Typography>
        )}
        {priceDiscount !== price ? (
          <>
            <Typography
              sx={{
                textDecoration: "line-through",
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            >
              €{parseFloat(price).toFixed(2)}
            </Typography>
            <Typography
              fontWeight="bold"
              fontSize={isMobile ? "0.9rem" : "1rem"}
            >
              €{parseFloat(priceDiscount).toFixed(2)}
            </Typography>
          </>
        ) : (
          <Typography fontSize={isMobile ? "0.9rem" : "1rem"}>
            €{parseFloat(price).toFixed(2)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(Item);
