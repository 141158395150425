import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const aboutContent = [
  {
    title: "Het beste vuurwerk in de Bommelerwaard",
    text: "Sinds 2015 bieden wij het mooiste vuurwerk voor een onvergetelijke jaarwisseling. Laat je betoveren door onze zorgvuldig samengestelde collectie.",
  },
  {
    title: "Goedkoopste in de regio",
    text: "We zijn trots op onze scherpe prijzen, zonder concessies te doen aan de kwaliteit.",
  },
  {
    title: "Grootste assortiment",
    text: "Van indrukwekkende compounds tot siervuurwerk voor jong en oud, wij hebben het allemaal.",
  },
  {
    title: "Parkeren voor de deur",
    text: "Bij ons parkeer je gemakkelijk en gratis voor de deur, zonder gedoe.",
  },
  {
    title: "Gratis friet bij afhalen",
    text: "Maak het afhalen extra leuk met een gratis frietje tijdens de feestelijke vuurwerkdagen.",
  },
  {
    title: "Ervaringsdeskundigen staan voor je klaar",
    text: "Ons deskundige team geeft advies op maat, zodat je veilig en verantwoord van het vuurwerk kunt genieten.",
  },
  {
    title: "Plaatsen in de regio",
    text: "Wij bedienen klanten uit Zaltbommel, Neerijnen, Haaften, Gameren, Bruchem, Kerkdriel, en meer!",
  },
  {
    title: "Veilige en verantwoorde afsteekinstructies",
    text: "Wij voorzien je van de beste tips om vuurwerk veilig af te steken, met duidelijke instructies en producten voor extra veiligheid zoals brillen en lonten.",
  },
  {
    title: "Snelle en eenvoudige online bestellingen",
    text: "Met ons gebruiksvriendelijke platform bestel je snel je vuurwerk online, met handige afhaalopties en betaalmethoden.",
  },
];

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Box
      width="80%"
      margin="60px auto"
      padding="20px"
      borderRadius="10px"
      className="shift-on-search"
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        textAlign="center"
        fontWeight="bold"
        color="primary"
        marginBottom="20px"
      >
        Over Vuurwerk Bruchem
      </Typography>

      <Typography
        variant="h6"
        textAlign="center"
        color="textSecondary"
        marginBottom="40px"
      >
        Ontdek waarom Vuurwerk Bruchem de nummer één vuurwerkspecialist is in de
        Bommelerwaard!
      </Typography>

      <Grid container spacing={4}>
        {aboutContent.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: "20px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#ffffff",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                {item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box marginTop="60px" textAlign="center">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: "30px",
            padding: "15px 30px",
            fontSize: "1.2rem",
            fontWeight: "bold",
            backgroundColor: "#e3197e",
            "&:hover": {
              backgroundColor: "#c71585",
            },
          }}
          onClick={() => navigate("/products")} // Redirect to /products
        >
          Bekijk ons assortiment
        </Button>
      </Box>
    </Box>
  );
};

export default AboutUs;
