// src/components/Confirmation2.js

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { clearCart } from "../../state";
import { fetcher2 } from "../../api";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Confirmation2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State om te bepalen of we een print prompt moeten tonen (voor print=true op mobiel)
  const [showPrintPrompt, setShowPrintPrompt] = useState(false);

  // Function to clean up cookies
  const clearCookiesExcept = (exceptions) => {
    document.cookie.split(";").forEach((c) => {
      const key = c.split("=")[0].trim();
      if (!exceptions.includes(key)) {
        document.cookie =
          key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    });
  };

  // Function to clean up storage (both local and session)
  const clearStorageExcept = (exceptions, storageType) => {
    Object.keys(storageType).forEach((key) => {
      if (!exceptions.includes(key)) {
        storageType.removeItem(key);
      }
    });
  };

  // Clear cookies and storage, except 'favorites', 'cookiesAccepted' and 'jwt', after the component is fully loaded
  useEffect(() => {
    window.addEventListener("load", () => {
      clearCookiesExcept(["favorites", "jwt", "cookiesAccepted"]);
      clearStorageExcept(["favorites", "jwt", "cookiesAccepted"], localStorage);
      clearStorageExcept(
        ["favorites", "jwt", "cookiesAccepted"],
        sessionStorage
      );
    });
  }, []);

  const [order, setOrder] = useState(null);
  const { sessionId: urlSessionId } = useParams();
  const storedSessionId = localStorage.getItem("recentSessionId");
  const sessionId = urlSessionId || storedSessionId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const iframeRef = useRef(null); // Ref voor het iframe

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const shouldPrint = queryParams.get("print") === "true";

  const getFreeProducts = async (totalAmount) => {
    const data = await fetcher2(`free-items?populate=*`);

    // Separate products with freeFromPrice of 0
    const alwaysFreeProducts = data.data.filter(
      (p) => p.attributes.freeFromPrice === 0
    );

    // Combine the always free products and the most expensive qualified product
    const combinedFreeProducts = [...alwaysFreeProducts];

    return combinedFreeProducts;
  };

  useEffect(() => {
    dispatch(clearCart());

    const fetchOrder = async () => {
      if (!sessionId) {
        console.error("No session id");
        return;
      }
      try {
        const orderData = await fetcher2(
          `orders?filters[stripeSessionId]=${sessionId}`
        );

        if (orderData.data?.length > 0) {
          const fullOrder = orderData.data[0];
          const totalAmount = fullOrder.attributes.products.reduce(
            (acc, product) => acc + product.price * product.count,
            0
          );
          const freeProducts = await getFreeProducts(totalAmount);
          const updatedOrder = {
            ...fullOrder.attributes,
            id: fullOrder.id,
            products: [
              ...fullOrder.attributes.products,
              ...freeProducts.map((product) => {
                const actualProduct = product.attributes
                  ? product.attributes
                  : product;
                return {
                  ...actualProduct,
                  price: 0,
                  count: 1,
                };
              }),
            ],
          };
          setOrder(updatedOrder);
          updatedOrder.products.forEach((product, index) => {
            console.log(`Product ${index + 1}:`, product);
          });
        }
      } catch (error) {
        console.error("Error fetching order", error);
      }
    };

    fetchOrder();

    return () => {
      localStorage.removeItem("recentSessionId");
    };
  }, [sessionId, dispatch]);

  // Function to generate the PDF with fixed A4 size and consistent text sizes
  const generatePDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    let yPosition = 10;

    // Set font sizes
    const titleFontSize = 18;
    const headerFontSize = 12;
    const textFontSize = 10;

    // Title
    pdf.setFontSize(titleFontSize);
    pdf.text("VUURWERK BRUCHEM", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 10;

    // Contact Information
    pdf.setFontSize(textFontSize);
    pdf.text("Dorpsstraat 20", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("5314 AE Bruchem", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("0418 700 511", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("VUURWERKBRUCHEM.NL", pageWidth / 2, yPosition, {
      align: "center",
    });
    yPosition += 10;

    pdf.line(10, yPosition, pageWidth - 10, yPosition);
    yPosition += 10;

    // Order Details
    pdf.setFontSize(headerFontSize);
    pdf.text("Bestelgegevens", 10, yPosition);
    yPosition += 8;

    pdf.setFontSize(textFontSize);
    pdf.text(`Bestelnummer: ${order.id}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Naam: ${order.userName}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Email: ${order.email}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Telefoon: ${order.phoneNumber}`, 10, yPosition);
    yPosition += 10;

    // Pickup Details
    pdf.setFontSize(headerFontSize);
    pdf.text("Afhaalgegevens", 10, yPosition);
    yPosition += 8;

    pdf.setFontSize(textFontSize);
    pdf.text(`Datum: ${order.pickupDate}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Tijd: ${order.pickupTimeSlot}`, 10, yPosition);
    yPosition += 6;
    pdf.text(
      `Status: ${
        order.stripePaymentStatus === "paid"
          ? "Betaald"
          : order.stripePaymentStatus === "contant"
          ? "Contant, nog te betalen bij afhalen via pin of contant"
          : order.stripePaymentStatus === "cancelled"
          ? "Geannuleerd, deze bestelling is geannuleerd en kan niet opgehaald worden."
          : order.stripePaymentStatus === "refunded"
          ? "Terugbetaald, deze bestelling is terugbetaald en kan niet opgehaald worden."
          : order.stripePaymentStatus
      }`,
      10,
      yPosition
    );
    yPosition += 10;

    // Products Table
    pdf.setFontSize(headerFontSize);
    pdf.text("Producten", 10, yPosition);
    yPosition += 8;

    const tableColumn = ["Art. Nummer", "Naam", "Prijs", "Aantal", "Subtotaal"];
    const tableRows = [];

    order.products.forEach((product) => {
      const rowData = [
        product.artNumber || "",
        product.name || "",
        `€${product.price ? product.price.toFixed(2) : "0.00"}`,
        product.count.toString(),
        `€${(product.price * product.count).toFixed(2)}`,
      ];
      tableRows.push(rowData);
    });

    pdf.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: yPosition,
      theme: "grid",
      styles: {
        fontSize: textFontSize,
      },
      headStyles: {
        fillColor: [200, 200, 200],
      },
      margin: { left: 10, right: 10 },
    });

    yPosition = pdf.autoTable.previous.finalY + 10;

    // Total
    const totalAmount = order.products
      .reduce((acc, product) => acc + product.price * product.count, 0)
      .toFixed(2);

    pdf.setFontSize(textFontSize);
    pdf.text(`Totaal: €${totalAmount}`, 10, yPosition);
    yPosition += 10;

    // Footer Message
    pdf.setFontSize(textFontSize);
    pdf.text(
      "Dank voor uw bestelling bij Vuurwerk Bruchem!",
      pageWidth / 2,
      yPosition,
      { align: "center" }
    );

    // Page Numbers
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(
        `Pagina ${i} van ${pageCount}`,
        pageWidth / 2,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }

    return pdf;
  };

  // Function to handle printing
  const handlePrint = () => {
    const pdf = generatePDF();
    const blobURL = pdf.output("bloburl");

    if (isMobile) {
      // Voor mobiele apparaten, open een nieuw venster direct
      const newWindow = window.open("", "_blank");

      if (newWindow) {
        // Zet een laadindicator of een bericht in het nieuwe venster
        newWindow.document.write("<p>De PDF wordt geladen...</p>");

        // Wacht even om ervoor te zorgen dat het nieuwe venster is geopend
        setTimeout(() => {
          newWindow.location.href = blobURL;

          // Optioneel: Voeg een event listener toe om printen te activeren zodra de PDF is geladen
          newWindow.onload = () => {
            newWindow.focus();
            newWindow.print();
          };
        }, 100);
      } else {
        // Als het nieuwe venster niet geopend kan worden, geef een alert met een directe link
        alert(
          "Het openen van de PDF is geblokkeerd. Klik hier om de PDF te openen: " +
            blobURL
        );
      }
    } else {
      // Voor desktop apparaten, gebruik de iframe methode
      if (iframeRef.current) {
        iframeRef.current.src = blobURL;

        // Wacht tot het iframe geladen is en activeer de print dialoog
        iframeRef.current.onload = () => {
          iframeRef.current.contentWindow.focus();
          iframeRef.current.contentWindow.print();
        };
      }
    }
  };

  // useEffect om te controleren op print=true en het tonen van een print prompt
  useEffect(() => {
    if (shouldPrint && order) {
      if (isMobile) {
        // Op mobiele apparaten tonen we een knop voor de gebruiker om te klikken
        setShowPrintPrompt(true);
      } else {
        // Op desktop apparaten printen we automatisch
        handlePrint();
      }
    }
  }, [shouldPrint, order, isMobile]);

  if (!order) {
    return (
      <Box className="shift-on-search" m="60px auto" height="50vh">
        <Alert severity="error">
          <AlertTitle>Error!</AlertTitle>
          Er is een probleem opgetreden bij het ophalen van de orderdetails.
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      className="shift-on-search"
      m="60px auto"
      width={isMobile ? "95%" : "80%"}
      height="100%"
      p={isMobile ? 2 : 3}
    >
      {/* Embed het PDF iframe (zichtbaar of verborgen) */}
      <iframe
        ref={iframeRef}
        style={{ display: "none" }}
        title="Afhaalbewijs PDF"
      ></iframe>

      <Alert
        severity={
          order.stripePaymentStatus === "cancelled" ||
          order.stripePaymentStatus === "refunded"
            ? "error"
            : "success"
        }
      >
        <AlertTitle>
          {order.stripePaymentStatus === "paid"
            ? "Gelukt!"
            : order.stripePaymentStatus === "cancelled"
            ? "Bestelling Geannuleerd"
            : order.stripePaymentStatus === "refunded"
            ? "Bestelling Terugbetaald"
            : order.stripePaymentStatus === "contant"
            ? "LET OP!"
            : "Onbekende Status"}
        </AlertTitle>
        {order.stripePaymentStatus === "paid" &&
          "Je bestelling is succesvol ontvangen, dankjewel! — Je kunt hieronder je afhaalbewijs afdrukken."}
        {order.stripePaymentStatus === "cancelled" &&
          "Je bestelling is geannuleerd en kan niet worden opgehaald. Neem contact op voor verdere vragen."}
        {order.stripePaymentStatus === "refunded" &&
          "Je bestelling is terugbetaald en kan niet worden opgehaald. Neem contact op voor verdere vragen."}
        {order.stripePaymentStatus === "contant" &&
          "Je hebt gekozen voor betaling in de winkel (contant of pin). Dit dient nog voldaan te worden bij afhalen via pin of contant."}
      </Alert>

      {/* On-screen content */}
      <Box
        id="printArea"
        m="20px auto"
        p={isMobile ? 2 : 3}
        sx={{
          width: "100%",
        }}
      >
        <Box display="flex" justifyContent="flex-start">
          <Typography
            variant={isMobile ? "h5" : "h3"}
            component="h3"
            gutterBottom
            className="vuurwerk-bruchem-text"
          >
            <b>VUURWERK BRUCHEM</b>
          </Typography>
        </Box>

        <Typography align="left" gutterBottom>
          Dorpsstraat 20
        </Typography>
        <Typography align="left" gutterBottom>
          5314 AE Bruchem
        </Typography>
        <Typography align="left" gutterBottom>
          0418 700 511
        </Typography>
        <Typography align="left" gutterBottom>
          VUURWERKBRUCHEM.NL
        </Typography>

        <hr style={{ margin: "20px 0" }} />

        <Box my={3}>
          <Typography>
            <strong>Bestelnummer:</strong> {order.id}
          </Typography>
          <Typography>
            <strong>Naam:</strong> {order.userName}
          </Typography>
          <Typography>
            <strong>Email:</strong> {order.email}
          </Typography>
          <Typography>
            <strong>Telefoon:</strong> {order.phoneNumber}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Afhaalgegevens
        </Typography>
        <Typography>
          <strong>Datum:</strong> {order.pickupDate} december 2024
        </Typography>
        <Typography>
          <strong>Tijd:</strong> {order.pickupTimeSlot}
        </Typography>
        <Typography>
          <strong>Status:</strong>{" "}
          {order.stripePaymentStatus === "paid"
            ? "Betaald"
            : order.stripePaymentStatus === "contant"
            ? "Contant, nog te betalen bij afhalen via pin of contant"
            : order.stripePaymentStatus === "cancelled"
            ? "Geannuleerd, deze bestelling is geannuleerd en kan niet opgehaald worden."
            : order.stripePaymentStatus === "refunded"
            ? "Terugbetaald, deze bestelling is terugbetaald en kan niet opgehaald worden."
            : order.stripePaymentStatus}
        </Typography>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Producten
          </Typography>
          <Box overflowX="auto">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                    Art. Nummer
                  </th>
                  <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                    Naam
                  </th>
                  <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                    Prijs
                  </th>
                  <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                    Aantal
                  </th>
                  <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                    Subtotaal
                  </th>
                </tr>
              </thead>

              <tbody>
                {order.products.map((product, index) => (
                  <tr key={index}>
                    <td style={{ paddingBottom: "8px" }}>
                      {product.artNumber || ""}
                    </td>
                    <td style={{ paddingBottom: "8px" }}>{product.name}</td>
                    <td style={{ paddingBottom: "8px" }}>
                      €{product.price ? product.price.toFixed(2) : "0.00"}
                    </td>
                    <td style={{ paddingBottom: "8px" }}>{product.count}</td>
                    <td style={{ paddingBottom: "8px" }}>
                      €{(product.price * product.count).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4" style={{ paddingTop: "10px" }}>
                    <strong>Totaal:</strong>
                  </td>
                  <td style={{ paddingTop: "10px" }}>
                    <strong>
                      €
                      {order.products
                        .reduce(
                          (acc, product) => acc + product.price * product.count,
                          0
                        )
                        .toFixed(2)}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
        </Box>

        <Typography align="center" gutterBottom>
          Dank voor uw bestelling bij Vuurwerk Bruchem!
        </Typography>
      </Box>

      {/* Print button of Print prompt */}
      {isMobile ? (
        showPrintPrompt ? (
          <Box mt={3} textAlign="center">
            <Button variant="contained" color="primary" onClick={handlePrint}>
              Print Afhaalbewijs
            </Button>
          </Box>
        ) : null
      ) : (
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Print Afhaalbewijs
        </Button>
      )}
    </Box>
  );
};

export default Confirmation2;
